import React from 'react';
import './Scss/Header.scss';
import Slider from 'react-slick';
import { useQuery } from 'react-query';
import Lenta from './images/lenta.png';
import { useTranslation } from 'react-i18next';
import { fetchSlidersData } from '../../api/Api';
import { getDescription, getName } from '../../languages/language';

const Header = () => {

    const settings = {
        dots: true,
        speed: 2000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        autoplaySpeed: 4000,
        className: "slider variable-width",
    };

    // i18next

    const { t } = useTranslation()
    let lang = localStorage.getItem('i18nextLng')

    // data of slider

    const { data } = useQuery('sliders', fetchSlidersData);

    return (
        <div className='Header' id="head">
            <div className='wrapper'>
                <div className='carousel-slide'>
                    <Slider {...settings} className="slider">
                        {data?.data.map((data) => {
                            return (
                                <div key={data.id} className='slider-page col-md-12'>
                                    <div className='body'>
                                        <img src={data.image} />
                                        <h1 className='title' style={{ color: `${data.color ? data.color : "black"}`, fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}` }}>{getName(data)}</h1>
                                        <p className='desc' style={{ color: `${data.color ? data.color : "black"}`, fontFamily: `${lang == "ru" ? "CraftWork500" : "zonapro"}` }}>{getDescription(data)}</p>
                                        <div className='link'>
                                            <a href={data.url} className='explore' style={{ color: `${data.color ? "black" : "white"}`, backgroundColor: `${data.color ? data.color : "black"}`, fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}` }}>{t("now")}</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <img src={Lenta} className="lenta" alt="lenta" />
        </div>
    );
};

export default Header;