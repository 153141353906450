import axios from "axios";
import { DATA_ADVERTISING_URL, DATA_CATEGORIES_URL, DATA_EVENTS_URL, DATA_NEWS_URL, DATA_PRODUCTS_URL, DATA_SEARCH_PRODUCTS_URL, DATA_SLIDER_URL, DATA_SUB_CATEGORIES_URL } from "./ApiUrl";

// sliders API

export const fetchSlidersData = () => {
    return axios.get(DATA_SLIDER_URL)
};

// categories API

export const fetchCategoriesData = () => {
    return axios.get(DATA_CATEGORIES_URL)
};

// sub-categories API

export const fetchSubCategoriesData = () => {
    return axios.get(DATA_SUB_CATEGORIES_URL)
};

// products API

export const fetchProductsData = () => {
    return axios.get(DATA_PRODUCTS_URL)
};

// searching products API

export const fetchSearchProductsData = () => {
    return axios.get(DATA_SEARCH_PRODUCTS_URL)
};

// news API

export const fetchNewsData = () => {
    return axios.get(DATA_NEWS_URL)
};

// events API

export const fetchEventsData = () => {
    return axios.get(DATA_EVENTS_URL)
};

// advertising API

export const fetchAdvertisingData = () => {
    return axios.get(DATA_ADVERTISING_URL)
};