import axios from 'axios';
import './Scss/TopProducts.scss';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { DATA_PRODUCTS_URL } from '../../api/ApiUrl';
import ProductItem from '../ProductItem/ProductItem';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';


const TopProducts = () => {

    // data of top products

    const [pageId, setPageId] = useState(1);
    let url = `${DATA_PRODUCTS_URL}?page=${pageId}&top=true`;
    const [dataTopProducts, setDataTopProducts] = useState([]);

    useEffect(() => {

        async function fetchTopProducts() {
            const request = await axios.get(url);
            setDataTopProducts([...dataTopProducts, request?.data]);
            return request;
        };

        fetchTopProducts();

    }, [url]);

    function decrement() {
        if (dataTopProducts[dataTopProducts?.length - 1]?.next) {
            setPageId((c) => c + 1)
        } else {
            setPageId(1)
        }
    };

    // i18next

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    return (
        <div className='TopProducts'>
            <div className="wrapper">
                <div className='head'>
                    <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t(7)}</h1>
                    <p className="prod-length" style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>({dataTopProducts[0]?.count} {lang == "uz" && "ta mahsulot"}{lang == "ru" && "продуктов"}{lang == "en" && "products"}{lang == "zh" && "個產品"}{lang == "sw" && "bidhaa"})</p>
                </div>
                <div className="products">
                    {dataTopProducts?.map((data) => (
                        data?.results?.map((item) => (
                            <div key={item.id}>
                                <ProductItem mainData={item} />
                            </div>
                        ))
                    ))}
                </div>
                <div className="more">
                    {dataTopProducts[dataTopProducts?.length - 1]?.next ?
                        <div onClick={decrement} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="explore">{lang == "uz" && "Ko'proq"}{lang == "ru" && "Более"}{lang == "en" && "More"}{lang == "zh" && "更多的"}{lang == "sw" && "Zaidi"} <ArrowDownward className="down-icon" /></div>
                        :
                        <div onClick={decrement} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="explore">{lang == "uz" && "Yopish"}{lang == "ru" && "Неисправность"}{lang == "en" && "Close"}{lang == "zh" && "關閉"}{lang == "sw" && "Kuzimisha"} <ArrowUpward className="down-icon" /></div>
                    }
                </div>
            </div>
        </div>
    );
};

export default TopProducts;