import React from 'react';
import News from '../components/News/News';
import Categories from '../components/Category';
import Events from '../components/Events/Events';
import Header from '../components/Header/Header';
import CardSimple from '../components/Card/Card';
import Advertising from '../components/Advertising/Advertising';
import TopProducts from '../components/TopProducts/TopProducts';

const HeaderRoute = ({ news, events }) => {
    return (
        <div>
            <Header />
            <Categories main={true} all={false} />
            <div ref={events}>
                <TopProducts />
            </div>
            <Advertising />
            <div ref={news}>
                <News home={true} individual={false} />
            </div>
            <CardSimple />
            <Events home={true} individual={false} />
        </div>
    );
};

export default HeaderRoute;