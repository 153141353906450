import React, { useState } from 'react';
import './Scss/ProductItem.scss';
import { Link } from 'react-router-dom';
import { Check } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { getName } from '../../languages/language';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsCart, addProductsCount, addProductsSaved } from '../../redux/actions/planActions';

const ProductItem = ({ mainData }) => {

    // redux with localstorage

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    let addedProducts = useSelector((state) => state.cartProducts.products);
    let savedProducts = useSelector((state) => state.savedProducts.products);
    let countProducts = useSelector((state) => state.countProducts.products);

    function addToCart(item) {
        dispatch(addProductsCart(addedProducts?.includes(item.id) ? [...addedProducts?.filter((c) => c != item.id)] : [...addedProducts?.filter((c) => c != item.id), item.id]));
        dispatch(addProductsCount(countProducts?.filter((c) => c?.id == item.id).length != 0 ? [...countProducts?.filter((c) => c?.id != item.id)] : [...countProducts?.filter((c) => c?.id != item.id), { id: item.id, name: item.name_uz, price: item.price, count: item.minimum == 0 ? 1 : item.minimum }]))
        if (!addedProducts?.includes(item.id)) {
            setShowModal(true);
            setTimeout(() => {
                setShowModal(false)
            }, 1500);
        }
    };

    function saveProduct(item) {
        dispatch(addProductsSaved(savedProducts?.includes(item) ? [...savedProducts?.filter((c) => c != item)] : [...savedProducts?.filter((c) => c != item), item]));
    };

    // i18next 

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    return (
        <div className='ProductItem'>
            <div className="imgs">
                <div className="top-tools">
                    <div className={`percent ${mainData.persent == 0 && "opacity-0"}`}>{mainData.persent} % Off</div>
                    <div className="save-btn" onClick={() => saveProduct(mainData.id)}>
                        {savedProducts?.includes(mainData.id) ?
                            <i className="fa fa-heart"></i>
                            :
                            <i className="fa fa-heart-o"></i>
                        }
                    </div>
                </div>
                <Link to={`/product/${mainData.id}`} className='img'>
                    <img src={mainData.image} alt="img" />
                </Link>
            </div>
            <Link to={`/product/${mainData.id}`} className="texts">
                <h3 className='name' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{getName(mainData)} <span className='price'>({mainData.price} $)</span></h3>
                <span style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="qqs">({t("qqs")})</span>
            </Link>
            <button className='addcart-btn' onClick={() => addToCart(mainData)} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>
                {addedProducts?.includes(mainData.id) ? t("added") : t("cart")}
            </button>
            <div className={`succes-modal ${showModal && "show-succes-modal"}`}>
                <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="succes-title">{t("succes")} <Check /></h1>
            </div>
        </div>
    );
};

export default ProductItem;