import axios from 'axios';
import './Scss/CartProducts.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { getName } from '../../languages/language';
import { DATA_PRODUCTS_URL } from '../../api/ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { addProductsCart, addProductsCount, addProductsSaved } from '../../redux/actions/planActions';

const CartProducts = ({ changeModal }) => {

    // i18next

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng');

    // redux with localstorage

    const dispatch = useDispatch();
    let addedProducts = useSelector((state) => state.cartProducts.products);
    let savedProducts = useSelector((state) => state.savedProducts.products);
    let countProducts = useSelector((state) => state.countProducts.products);

    function addToCart(item) {
        dispatch(addProductsCart(addedProducts.includes(item.id) ? [...addedProducts?.filter((c) => c != item.id)] : [...addedProducts?.filter((c) => c != item.id), item.id]));
        dispatch(addProductsCount(countProducts?.filter((c) => c?.id == item.id).length != 0 ? [...countProducts?.filter((c) => c?.id != item.id)] : [...countProducts?.filter((c) => c?.id != item.id), { id: item.id, name: item.name_uz, price: item.price, count: item.minimum == 0 ? 1 : item.minimum }]))
    };

    function saveProduct(item) {
        dispatch(addProductsSaved(savedProducts.includes(item) ? [...savedProducts?.filter((c) => c != item)] : [...savedProducts?.filter((c) => c != item), item]));
    };

    // data of cart products

    const [dataCartProducts, setDataCartProducts] = useState([]);
    let url = `${DATA_PRODUCTS_URL}?id__in=${addedProducts}`

    useEffect(() => {

        async function fetchCartProducts() {
            const request = await axios.get(url);
            setDataCartProducts(request?.data);
            return request;
        };

        fetchCartProducts();

    }, [url]);

    // counter

    function increment(item, count) {
        dispatch(addProductsCount([...countProducts?.filter((c) => c?.id != item.id), { id: item.id, name: item.name_uz, price: item.price, count: Number(countProducts?.filter((c) => c?.id == item.id).map((k) => k.count)) + count }]))
    };

    function decrement(item, count) {
        dispatch(addProductsCount(Number(countProducts?.filter((c) => c?.id == item.id).map((k) => k.count)) > count ? [...countProducts?.filter((c) => c?.id != item.id), { id: item.id, name: item.name_uz, price: item.price, count: Number(countProducts?.filter((c) => c?.id == item.id).map((k) => k.count)) - count }] : countProducts))
    };

    let totalPrice = 0;

    for (let i = 0; i < countProducts?.length; i++) {
        totalPrice += Number(countProducts[i].count) * Number(countProducts[i].price);
    };

    // modal options

    const [showModal, setShowModal] = useState(false);

    const [file, setfile] = useState();
    const [date, setDate] = useState("");
    const [email, setEmail] = useState("");
    const [value, setValue] = useState("");
    const [innNumber, setinnNumber] = useState("");
    const [nameValue, setNameValue] = useState("");
    const [numberValue, setNumberValue] = useState("");
    const [userPosition, setUserPosition] = useState("");

    const [redInn, setredInn] = useState(false);
    const [redFile, setRedFile] = useState(false);
    const [redName, setRedName] = useState(false);
    const [redText, setRedText] = useState(false);
    const [redDate, setRedDate] = useState(false);
    const [redEmail, setRedEmail] = useState(false);
    const [redNumber, setRedNumber] = useState(false);
    const [redPosition, setRedPosition] = useState(false);

    function handleChangeName(item) {
        setNameValue(item)
        setRedName(false)
    };

    function handleChangeNumber(item) {
        setNumberValue(item)
        setRedNumber(false)
    };

    function handleChangeValue(item) {
        setValue(item)
        setRedText(false)
    };

    function handleChangeDate(item) {
        setDate(item)
        setRedDate(false)
    };

    function handleChangeEmail(item) {
        setEmail(item)
        setRedEmail(false)
    };

    function handleChangePosition(item) {
        setUserPosition(item)
        setRedPosition(false)
    };

    function handleChangeFile(e) {
        setfile(e.target.files[0])
        setRedFile(false)
    };

    function handleChangeInn(item) {
        setinnNumber(item)
        setredInn(false)
    };

    let bot = {
        TOKEN: "5500966862:AAFWxVHRtMUeL4gr5NhS_OHkA9CWfIRW18o",
        chatID: "-1001566786338",
        message: `Kompaniya nomi: ${nameValue}; %0AKompaniya Joylashuvi: ${value}; %0AKompaniya ochilgan sana: ${date}; %0AKompaniya telefon raqami: ${numberValue}; %0AKompaniya emaili: ${email}; %0ATo'ldiruvchining lavozimi: ${userPosition}; %0AINN: ${innNumber}; %0AGuvohnoma: "". %0A<---------> %0AUmumiy summa: $ ${totalPrice}; %0ASotib olayotgan mahsulotlari: ${countProducts.map((item) => `${item.name} dan ${item.count} ta ($ ${Number(item.count) * Number(item.price)})`)}`
    }

    const [robot, setrobot] = useState(false);
    const [robotRed, setrobotRed] = useState(false);

    function verifyBot() {
        setrobot(true)
        setrobotRed(false)
    };

    function sendMessage(e) {
        if (nameValue === "") {
            setRedName(true)
        } else if (value === "") {
            setRedText(true)
        } else if (date === "") {
            setRedDate(true)
        } else if (numberValue === "") {
            setRedNumber(true)
        } else if (isNaN(numberValue)) {
            setRedNumber(true)
        } else if (email === "") {
            setRedEmail(true)
        } else if (!email.includes("@") || !email.includes(".")) {
            setRedEmail(true)
        } else if (userPosition === "") {
            setRedPosition(true)
        } else if (innNumber === "") {
            setredInn(true)
        } else if (file == undefined) {
            setRedFile(true)
        }
        else if (robot == false) {
            setrobotRed(true)
        }
        else {
            // send message text

            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method: "GET"
            })
                .then(success => {
                    console.log('send message')
                }, error => {
                    console.log(error)
                })

            // send message file

            const formData = new FormData()
            formData.append('chat_id', bot.chatID);
            formData.append('document', file)

            axios.post(`https://api.telegram.org/bot${bot.TOKEN}/sendDocument`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(success => {
                    console.log('send message')
                }, error => {
                    console.log(error)
                })

            setNameValue("")
            setNumberValue("")
            setValue("")
            setDate("")
            setEmail("")
            setUserPosition("")
            setinnNumber("")
            setfile(null)
            setShowModal(false)
            changeModal()
            setTimeout(() => {
                dispatch(addProductsCart([]));
                dispatch(addProductsCount([]));
            }, 1000);
        }
    }

    return (
        <>
            <div className='CartProducts'>
                <div className="wrapper">
                    <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("my")}</h1>
                    {addedProducts.length > 0 ?
                        <div className="products">
                            {dataCartProducts?.results?.map((item) => (
                                <div key={item.id} className="product">
                                    <img src={item.image} alt="img" className='img' />
                                    <div className="middle">
                                        <div className="top">
                                            <h4 className="name" style={{ fontFamily: `${lang == "ru" ? "CraftWork500" : "zonapro"}`, fontWeight: 700 }}>{getName(item)}</h4>
                                            <p className="price" style={{ fontFamily: `${lang == "ru" ? "CraftWork500" : "zonapro"}`, fontWeight: 700 }}>$ {item.price}</p>
                                        </div>
                                        <div className="bottom">
                                            <h4 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className="quantity">{t("quantity")}:</h4>
                                            <div className="counter">
                                                <button className="count" onClick={() => decrement(item, item.minimum == 0 ? 1 : item.minimum)}>-</button>
                                                <div className="count-number">{countProducts?.filter((c) => c?.id == item.id)?.map((k) => k.count)}</div>
                                                <button className="count" onClick={() => increment(item, item.minimum == 0 ? 1 : item.minimum)}>+</button>
                                            </div>
                                            <h4 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className="quantity">{t("total")}: $ {Number(countProducts?.filter((c) => c?.id == item.id)?.map((k) => k.count)) * item.price}</h4>
                                        </div>
                                    </div>
                                    <div className="icons">
                                        {savedProducts.includes(item.id) ?
                                            <i className="fa fa-heart" onClick={() => saveProduct(item.id)}></i>
                                            :
                                            <i className="fa fa-heart-o" onClick={() => saveProduct(item.id)}></i>
                                        }
                                        <i className="fa fa-trash-o" onClick={() => addToCart(item)}></i>
                                    </div>
                                </div>
                            ))}
                            <h1 className='top-title text-center mt-5' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("total")}: ${totalPrice}</h1>
                            <div className="col-12 d-flex justify-content-center">
                                <button className="btn" onClick={() => setShowModal(true)}>{t("buyall")}</button>
                            </div>
                        </div>
                        :
                        <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{lang == "uz" ? "Siz bu yerga mahsulotlar saqlamagansiz" : lang == "en" ? "You have not saved any products here" : lang == "ru" ? "Вы не сохранили здесь ни одного товара" : lang == "zh" ? "您尚未在此處保存任何產品" : "Hujahifadhi bidhaa zozote hapa"}</h1>
                    }
                </div>
            </div>
            {showModal &&
                <div className="market-modal">
                    <div className="contr-mod" onClick={() => setShowModal(false)}></div>
                    <div className="child-modal row">
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{t("date")}</label>
                            <input className={`${redDate && "red-line"} form-control`} value={date} onChange={(e) => handleChangeDate(e.target.value)} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{t("name")}</label>
                            <input className={`${redName && "red-line"} form-control`} value={nameValue} onChange={(e) => handleChangeName(e.target.value)} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{t("location")}</label>
                            <input className={`${redText && "red-line"} form-control`} value={value} onChange={(e) => handleChangeValue(e.target.value)} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{t("phone")}</label>
                            <input className={`${redNumber && "red-line"} form-control`} value={numberValue} onChange={(e) => handleChangeNumber(e.target.value)} type="number" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{t("email")}</label>
                            <input className={`${redEmail && "red-line"} form-control`} value={email} onChange={(e) => handleChangeEmail(e.target.value)} type="email" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{t("user")}</label>
                            <input className={`${redPosition && "red-line"} form-control`} value={userPosition} onChange={(e) => handleChangePosition(e.target.value)} type="text" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">Inn</label>
                            <input className={`${redInn && "red-line"} form-control`} value={innNumber} onChange={(e) => handleChangeInn(e.target.value)} type="number" />
                        </div>
                        <div className="col-md-6">
                            <label className="form-label" htmlFor="">{lang == "uz" && "Guvohnoma"} {lang == "ru" && "свидетельство"} {lang == "en" && "Testimony"} {lang == "zh" && "見證"} {lang == "sw" && "Ushuhuda"}</label>
                            <input className={`${redFile && "red-line"} form-control`} onChange={(e) => handleChangeFile(e)} type="file" />
                        </div>
                        <div className="col-md-12 d-flex align-items-center justify-content-center">
                            <ReCAPTCHA className={`recaptcha ${robotRed && "red-line"}`} sitekey="6LdKNYIhAAAAACWU0iKEPv0pk85_OFLd-FBGdMAp" onChange={() => verifyBot()} />
                        </div>
                        <div className="col-12 d-flex justify-content-center mt-5">
                            <button className="btn" onClick={() => sendMessage()}>{t("check")}</button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default CartProducts;