const BASE_URL = `https://api.madeinuzb.com`;

export const DATA_NEWS_URL = `${BASE_URL}/news/`;
export const DATA_EVENTS_URL = `${BASE_URL}/events/`;
export const DATA_SLIDER_URL = `${BASE_URL}/sliders/`;
export const DATA_PRODUCTS_URL = `${BASE_URL}/products/`;
export const DATA_ADVERTISING_URL = `${BASE_URL}/reklama/`;
export const DATA_CATEGORIES_URL = `${BASE_URL}/categories/`;
export const DATA_SUB_CATEGORIES_URL = `${BASE_URL}/subcategories/`;
export const DATA_SEARCH_PRODUCTS_URL = `${BASE_URL}/product_search/`;

