import axios from 'axios';
import './Scss/OneProduct.scss';
import { Check } from '@mui/icons-material';
import { Pagination } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import ProductItem from '../ProductItem/ProductItem';
import { DATA_PRODUCTS_URL } from '../../api/ApiUrl';
import { useDispatch, useSelector } from 'react-redux';
import { getDescription, getName } from '../../languages/language';
import { addProductsCart, addProductsCount, addProductsSaved } from '../../redux/actions/planActions';

const OneProduct = () => {

    // redux with localstorage

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    let addedProducts = useSelector((state) => state.cartProducts.products);
    let savedProducts = useSelector((state) => state.savedProducts.products);
    let countProducts = useSelector((state) => state.countProducts.products);

    function addToCart(item) {
        dispatch(addProductsCart(addedProducts?.includes(item.id) ? [...addedProducts?.filter((c) => c != item.id)] : [...addedProducts?.filter((c) => c != item.id), item.id]));
        dispatch(addProductsCount(countProducts?.filter((c) => c?.id == item.id).length != 0 ? [...countProducts?.filter((c) => c?.id != item.id)] : [...countProducts?.filter((c) => c?.id != item.id), { id: item.id, name: item.name_uz, price: item.price, count: item.minimum == 0 ? 1 : item.minimum }]))
        if (!addedProducts?.includes(item.id)) {
            setShowModal(true);
            setTimeout(() => {
                setShowModal(false)
            }, 1500);
        }
    };

    function saveProduct(item) {
        dispatch(addProductsSaved(savedProducts?.includes(item) ? [...savedProducts?.filter((c) => c != item)] : [...savedProducts?.filter((c) => c != item), item]));
    };

    // i18next 

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    // data of product

    const { id } = useParams();
    let url = `${DATA_PRODUCTS_URL}${id}/`;
    const [dataProduct, setDataProduct] = useState([]);

    useEffect(() => {

        async function fetchProduct() {
            const request = await axios.get(url);
            setDataProduct(request?.data);
            return request;
        };

        fetchProduct();

    }, [url]);

    // data of all products

    const [pageId, setPageId] = useState('');
    const [dataProducts, setDataProducts] = useState([]);
    let urlProducts = pageId == '' ? `${DATA_PRODUCTS_URL}?id=&id__in=&category=${dataProduct?.category}&top=&sub_category=${dataProduct?.sub_category}` : `${DATA_PRODUCTS_URL}?category=${dataProduct?.category}&id=&id__in=&page=${pageId}&sub_category=${dataProduct?.sub_category}&top=`

    useEffect(() => {

        async function fetchProducts() {
            const request = await axios.get(urlProducts);
            setDataProducts(request?.data);
            return request;
        };

        fetchProducts();

    }, [urlProducts]);

    // replace image

    const [mainImg, setMainImg] = useState(dataProduct?.image);

    return (
        <div className='OneProduct'>
            <div className="wrapper">
                <div className="product">
                    <div className="imgs">
                        <div className="save-btn">
                            {savedProducts?.includes(dataProduct?.id) ?
                                <i className="fa fa-heart" onClick={() => saveProduct(dataProduct.id)}></i>
                                :
                                <i className="fa fa-heart-o" onClick={() => saveProduct(dataProduct.id)}></i>
                            }
                        </div>
                        <div className="main-img">
                            {dataProduct?.video && mainImg == dataProduct?.video ?
                                <video>
                                    <source src={dataProduct?.video} />
                                </video>
                                :
                                <img src={mainImg ? mainImg : dataProduct?.image} alt="img" className='img' />
                            }
                        </div>
                        <div className="child-imgs">
                            <div className="child" onClick={() => setMainImg(dataProduct?.image)}>
                                <img src={dataProduct?.image} alt="img" className='img' />
                            </div>
                            <div className="child" onClick={() => setMainImg(dataProduct?.image1)}>
                                <img src={dataProduct?.image1} alt="img" className='img' />
                            </div>
                            <div className="child" onClick={() => setMainImg(dataProduct?.image2)}>
                                <img src={dataProduct?.image2} alt="img" className='img' />
                            </div>
                            {dataProduct?.video &&
                                <div className="child" onClick={() => setMainImg(dataProduct?.video)}>
                                    <video>
                                        <source src={dataProduct?.video} />
                                    </video>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="texts">
                        <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{getName(dataProduct)}</h1>
                        <div>
                            <p className="desc" style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{getDescription(dataProduct)}</p>
                            <p className="code" style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{lang == "en" && "Product code"} {lang == "ru" && "Код продукта"} {lang == "uz" && "Mahsulot kodi"} {lang == "zh" && "產品代碼"} {lang == "sw" && "Kanuni bidhaa"}: <span>{dataProduct?.product_code}</span></p>
                            <p className="code" style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{lang == "en" && "Price"} {lang == "ru" && "Цена"} {lang == "uz" && "Narxi"} {lang == "zh" && "價格"} {lang == "sw" && "Bei"}: <span>$ {dataProduct?.price}</span> <span style={{ color: "black", opacity: 0.7, fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>({t("qqs")})</span></p>
                        </div>
                        <button className='addcart-btn' onClick={() => addToCart(dataProduct)} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>
                            {addedProducts?.includes(dataProduct?.id) ? t("added") : t("cart")}
                        </button>
                    </div>
                </div>
                <h1 className='top-title recomend' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("recommend")}:</h1>
                <div className="products">
                    {dataProducts?.results?.slice(0, 4).map((item) => (
                        <div key={item.id}>
                            <ProductItem mainData={item} />
                        </div>
                    ))}
                </div>
                <div className="paginations">
                    <Pagination count={Math.ceil(dataProducts?.count / 8)} shape="rounded" onChange={(page) => setPageId(page.target.innerText)} className="pagination-item" />
                </div>
            </div>
            <div className={`succes-modal ${showModal && "show-succes-modal"}`}>
                <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="succes-title">{t("succes")} <Check /></h1>
            </div>
        </div>
    );
};

export default OneProduct;