import './I18next.js'
import './index.scss';
import App from './App';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Suspense fallback={<div className='loader' style={{ fontSize: "50px", fontWeight: 500, width: "100%", height: "100%", justifyContent: "center", display: "flex", alignItems: "center", position: "absolute" }}><div className="load"></div></div>}>
      <App />
    </Suspense>
  </React.StrictMode>
);
