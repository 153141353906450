import React, { useRef, useState } from 'react';
import { Provider } from 'react-redux';
import News from './components/News/News';
import About from './components/About/About';
import HeaderRoute from './routes/HeaderRoute';
import Categories from './components/Category';
import Footer from './components/Footer/Footer';
import Events from './components/Events/Events';
import Navbar from './components/Navbar/Navbar';
import { persistor, store } from './redux/store';
import Contacts from './components/Contacts/Contacts';
import Products from './components/Products/Products';
import ScrollToTop from './components/Scrolls/ScrollToTop';
import OneProduct from './components/OneProduct/OneProduct';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CartProducts from './components/CartProducts/CartProducts';
import SavedProducts from './components/SavedProducts/SavedProducts';

const App = () => {

  // i18nexus

  let lang = localStorage.getItem('i18nextLng');

  // Modal methods

  const [modalOk, setModalOk] = useState(true)

  function changeModal() {
    setModalOk(false)
  }

  // scrolls

  const news = useRef();
  const events = useRef();

  function changeScrollNews() {
    setTimeout(() => {
      window.scrollTo(0, news.current.offsetTop)
    }, 100);
  }

  function changeScrollEvents() {
    setTimeout(() => {
      window.scrollTo(0, events.current.offsetTop)
    }, 100);
  };

  // react-query

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop />
            <Navbar changeScrollNews={changeScrollNews} changeScrollEvents={changeScrollEvents} />
            <Routes>
              <Route exact path='/' element={<HeaderRoute news={news} events={events} />} />
              <Route path='/aboutus' element={<About />} />
              <Route path='/saved' element={<SavedProducts />} />
              <Route path='/cart' element={<CartProducts changeModal={changeModal} />} />
              <Route path="/news/:id" element={<News home={false} individual={true} />} />
              <Route path='/event:id' element={<Events home={false} individual={true} />} />
              <Route path='/categories' element={<Categories main={false} all={true} />} />
              <Route path='/products/:id' element={<Products />} />
              <Route path='/product/:id' element={<OneProduct />} />
            </Routes>
            <Contacts changeModal={changeModal} />
            <Footer changeModal={changeModal} />

            <div className={`contrast ${modalOk && "d-none"}`} onClick={() => setModalOk(true)}></div>
            <div className={`col-md-6 offset-md-3 buy-ok row ${!modalOk && "show-modal"}`}>
              {lang == "en" &&
                <h1>THANK YOU FOR YOUR APPEAL &#128522;</h1>
              }
              {lang == "ru"
                &&
                <h1>СПАСИБО 3A ОБРАЩЕНИЕ &#128522;</h1>
              }
              {lang == "uz" &&
                <h1>MUROJAATINGIZ UCHUN TASHAKKUR &#128522;</h1>
              }
              {lang == "zh" &&
                <h1>感謝您的呼籲 &#128522;</h1>
              }
              {lang == "sw" &&
                <h1>ASANTE KWA RUFAA YAKO &#128522;</h1>
              }
              <div className='col-md-4 offset-md-2'>
                <button className='btn btn-group' onClick={() => setModalOk(true)}>OK</button>
              </div>
            </div>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
