import './Scss/Footer.scss';
import { useQuery } from 'react-query';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Navbar/images/logo1.png';
import { useTranslation } from 'react-i18next';
import { fetchCategoriesData, fetchProductsData } from '../../api/Api';

const Footer = ({ changeModal }) => {

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    const [emailValue, setEmailValue] = useState("")
    const [redEmail, setRedEmail] = useState(false);

    function handleChange(item) {
        setEmailValue(item)
        setRedEmail(false)
    }

    let bot = {
        TOKEN: "5500966862:AAFWxVHRtMUeL4gr5NhS_OHkA9CWfIRW18o",
        chatID: "-1001566786338",
        message: `Email: ${emailValue}`
    }

    function sendMessage(e) {
        if (emailValue === "") {
            setRedEmail(true)
        } else {
            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method: "GET"
            })
                .then(success => {
                    console.log('send message')
                }, error => {
                    console.log(error)
                })

            setEmailValue("")
            changeModal()
        }
    };

    // data of products

    let dataProducts = useQuery('products', fetchProductsData);

    // data of categories

    const { data } = useQuery('categories', fetchCategoriesData);

    let dataCategories = data?.data;

    return (
        <div className="Footer">
            {/* <div className='wrapper'>
                <div className='f1 row'>
                    <div className='col-2 c1'>
                        <Link to="/" className='nav-link p-0' onClick={() => window.scrollTo(0, 0)}>
                            <img className='logo' src={Logo} />
                        </Link>
                        <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t(20)}</p>
                        <div className='icons'>
                            <a href='https://t.me/megastar_uzb'>
                                <i className='fa fa-telegram'></i>
                            </a>
                            <a href='https://www.instagram.com/7milliard.uae/'>
                                <i className='fa fa-instagram'></i>
                            </a>
                        </div>
                        {lang == "en" &&
                            <p className='mt-3' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Yashnabad district, 251 Moynaq street</p>
                        }
                        {lang == "ru" &&
                            <p className='mt-3' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Яшнабадский район, улица Мойнак 251</p>
                        }
                        {lang == "uz" &&
                            <p className='mt-3' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Yashnobod tumani, Mo'ynoq ko'chasi 251-uy</p>
                        }
                        {lang == "zh" &&
                            <p className='mt-3' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>亞甚納巴德區，莫伊納克街 251 號</p>
                        }
                        {lang == "sw" &&
                            <p className='mt-3' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Wilaya ya Yashnabad, 251 mtaa wa Moynaq</p>
                        }
                    </div>
                    <div className='col-3 c4'>
                        <h6 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("subscribe")}</h6>
                        <input style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className={`${redEmail && "red-line"}`} value={emailValue} type="email" placeholder={`${t("youremail")}...`} onChange={(e) => handleChange(e.target.value)} />
                        <button style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className='subs-btn d-block' onClick={() => sendMessage()} >{t(50)}</button>
                        <a className='d-block' href='tel:+99899-125-99-99' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>+99899 125 99 99</a>
                        <a className='d-block' href='tel:+99899-235-22-22' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>+99899 235 22 22</a>
                        <a className='d-block' href='mailto:info@madeinuzb.com' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>info@madeinuzb.com</a>
                        <a className='d-block' href='mailto:Support@madeinuzb.com' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Support@madeinuzb.com</a>
                    </div>
                    <div className='col-2 c2'>
                        <ul>
                            <li style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className='title'>{t('producst')}</li>
                            {dataProducts?.data?.data?.results?.slice(0, 6).map((item) => (
                                <li key={item.id} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>
                                    <Link to={`/product/${item.id}`} className='nav-link'>{lang == "en" && item.name_en} {lang == "ru" && item.name_ru} {lang == "uz" && item.name_uz} {lang == "zh" && item.name_ma} {lang == "sw" && item.name_sw}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='col-2 c2'>
                        <ul>
                            <li style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className='title'>{t("categ")}</li>
                            {dataCategories?.slice(0, 6).map((data) => (
                                <li key={data.id} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>
                                    <Link to={`/products/${data.id}`} className='nav-link'>{lang == "en" && data.name_en} {lang == "ru" && data.name_ru} {lang == "uz" && data.name_uz} {lang == "zh" && data.name_ma} {lang == "sw" && data.name_sw}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className='col-2 c2'>
                        <ul>
                            <li style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className='title'>{t("t1")}</li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#head' className='nav-link'>{t("t2")}</a></li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#categ' className='nav-link'>{t("t3")}</a></li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#adver' className='nav-link'>{t("t4")}</a></li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#best' className='nav-link'>{t("t5")}</a></li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#why' className='nav-link'>{t("t6")}</a></li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='#contacts' className='nav-link'>{t("t7")}</a></li>
                            <li><a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/aboutus' className='nav-link'>{t("t8")}</a></li>
                        </ul>
                    </div>
                    <div className='col-3 c3'>
                        <h6 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("subscribe")}</h6>
                        <input style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} value={emailValue} type="email" className={`form-control ${redEmail && "red-line"}`} placeholder={`${t("youremail")}...`} onChange={(e) => handleChange(e.target.value)} />
                        <button style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className='subs-btn d-block' onClick={() => sendMessage()}>{t(50)}</button>
                        <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='d-block' href='tel:+99899-125-99-99'>+99899 125 99 99</a>
                        <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='d-block' href='tel:+99899-235-22-22'>+99899 235 22 22</a>
                        <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='d-block' href='mailto:info@madeinuzb.com'>info@madeinuzb.com</a>
                        <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='d-block' href='mailto:Support@madeinuzb.com'>Support@madeinuzb.com</a>
                    </div>
                </div>
            </div> */}
            <div className='wrapper'>
                <div className='tool'>
                    <Link to="/" onClick={() => window.scrollTo(0, 0)} className="logo">
                        <img className='img' alt='logo' src={Logo} />
                    </Link>
                    <p className='footer-text' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t(20)}</p>
                    <div className='icons'>
                        <a href='https://t.me/megastar_uzb'>
                            <i className='fa fa-telegram'></i>
                        </a>
                        <a href='https://www.instagram.com/7milliard.uae/'>
                            <i className='fa fa-instagram'></i>
                        </a>
                    </div>
                    {lang == "en" &&
                        <p className='footer-text' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Yashnabad district, 251 Moynaq street</p>
                    }
                    {lang == "ru" &&
                        <p className='footer-text' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Яшнабадский район, улица Мойнак 251</p>
                    }
                    {lang == "uz" &&
                        <p className='footer-text' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Yashnobod tumani, Mo'ynoq ko'chasi 251-uy</p>
                    }
                    {lang == "zh" &&
                        <p className='footer-text' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>亞甚納巴德區，莫伊納克街 251 號</p>
                    }
                    {lang == "sw" &&
                        <p className='footer-text' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Wilaya ya Yashnabad, 251 mtaa wa Moynaq</p>
                    }
                </div>
                <div className='tool'>
                    <div className='footer-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t('producst')}</div>
                    {dataProducts?.data?.data?.results?.slice(0, 6).map((item) => (
                        <Link to={`/product/${item.id}`} className='footer-text links' key={item.id} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{lang == "en" && item.name_en} {lang == "ru" && item.name_ru} {lang == "uz" && item.name_uz} {lang == "zh" && item.name_ma} {lang == "sw" && item.name_sw}</Link>
                    ))}
                </div>
                <div className='tool'>
                    <div className='footer-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t('categ')}</div>
                    {dataCategories?.slice(0, 6).map((item) => (
                        <Link to={`/product/${item.id}`} className='footer-text links' key={item.id} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{lang == "en" && item.name_en} {lang == "ru" && item.name_ru} {lang == "uz" && item.name_uz} {lang == "zh" && item.name_ma} {lang == "sw" && item.name_sw}</Link>
                    ))}
                </div>
                <div className='tool'>
                    <div className='footer-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("t1")}</div>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#head' className='footer-text links'>{t("t2")}</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#categ' className='footer-text links'>{t("t3")}</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#adver' className='footer-text links'>{t("t4")}</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#best' className='footer-text links'>{t("t5")}</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/#why' className='footer-text links'>{t("t6")}</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='#contacts' className='footer-text links'>{t("t7")}</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href='/aboutus' className='footer-text links'>{t("t8")}</a>
                </div>
                <div className='tool'>
                    <div className='footer-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("subscribe")}</div>
                    <input style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} value={emailValue} type="email" className={`form-control footer-text ${redEmail && "red-line"}`} placeholder={`${t("youremail")}...`} onChange={(e) => handleChange(e.target.value)} />
                    <div>
                        <button style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className='subs-btn footer-text' onClick={() => sendMessage()}>{t(50)}</button>
                    </div>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='footer-text links' href='tel:+99899-125-99-99'>+99899 125 99 99</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='footer-text links' href='tel:+99899-235-22-22'>+99899 235 22 22</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='footer-text links' href='mailto:info@madeinuzb.com'>info@madeinuzb.com</a>
                    <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='footer-text links' href='mailto:Support@madeinuzb.com'>Support@madeinuzb.com</a>
                </div>
            </div>
        </div>
    );
};

export default Footer;