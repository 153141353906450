import React from 'react';
import './Scss/About.scss';
import videojs from 'video.js';
import Img1 from './images/p1.png';
import Img2 from './images/p2.png';
import Img3 from './images/p3.png';
import 'video.js/dist/video-js.css';
import Video from './video/Munis2.mp4'
import { useTranslation } from 'react-i18next';

const About = () => {

    // i18nexus

    const { t } = useTranslation()
    let lang = localStorage.getItem('i18nextLng')

    // videoplayer options and methods

    const options = {
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: Video,
            type: 'video/mp4'
        }]
    };

    const onReady = (player) => {
        playerRef.current = player;

        player.on('waiting', () => {
            player.log('player is waiting');
        });

        player.on('dispose', () => {
            player.log('player will dispose');
        });
    };

    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);

    React.useEffect(() => {
        if (!playerRef.current) {
            const videoElement = videoRef.current;

            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                player.log('player is ready');
                onReady && onReady(player);
            });
        }
    }, [options, videoRef]);

    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <>
            <div className={`About`}>
                <div className='wrapper1'>
                    <div className='col-md-12'>
                        <div className='video'>
                            <div data-vjs-player>
                                <video ref={videoRef} className='video-js vjs-big-play-centered' />
                            </div>
                        </div>
                        <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }} className={`name text-center`}>{t(21)}</h1>
                        <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t("abouttxt")}</p>
                        <div className='videos row'>
                            <div className='col-md-4'>
                                <div className='body'>
                                    <iframe src="https://www.youtube.com/embed/_ZH1WsIr2Zk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='body'>
                                    <iframe src="https://www.youtube.com/embed/och2BwtshWc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                            <div className='col-md-4'>
                                <div className='body'>
                                    <iframe src="https://www.youtube.com/embed/PtpOzjhV0I4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row partners'>
                        <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }} className='text-center mb-5'>{t("partners")}</h1>
                        <div className='col-4'>
                            <img src={Img1} />
                        </div>
                        <div className='col-4 border border-dark border-top-0 border-bottom-0'>
                            <img src={Img2} />
                        </div>
                        <div className='col-4'>
                            <img src={Img3} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default About;