import React from 'react';
import './Scss/Card.scss';
import Gerb from './images/gerb.png';
import { useTranslation } from 'react-i18next';
import { GroupSharp, Headset, LocalAtm } from '@mui/icons-material';

const CardSimple = () => {

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    return (
        <div className='Card' id="why">
            <div className='wrapper'>
                <div className='col-md-12'>
                    {lang == "uz" &&
                        <div className='texts'>
                            <h1 className='title' style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>Bizning afzalliklarimiz</h1>
                            <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Nega bizni tanlashingiz kerak?</p>
                        </div>
                    }
                    {lang == "ru" &&
                        <div className='texts'>
                            <h1 className='title' style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>Наши преимущества</h1>
                            <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Почему вы должны выбрать нас?</p>
                        </div>
                    }
                    {lang == "en" &&
                        <div className='texts'>
                            <h1 className='title' style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>Our advantages</h1>
                            <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Why should you choose us?</p>
                        </div>
                    }
                    {lang == "sw" &&
                        <div className='texts'>
                            <h1 className='title' style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>Faida zetu</h1>
                            <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>Kwa nini utuchague?</p>
                        </div>
                    }
                    {lang == "zh" &&
                        <div className='texts'>
                            <h1 className='title' style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>我們的優勢</h1>
                            <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>為什麼要選擇我們？</p>
                        </div>
                    }
                    <div className='body row'>
                        <div className='col-3'>
                            <div className='tool nav-link'>
                                <GroupSharp className='icon' />
                                <div className='d-block mx-'>
                                    <h5 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("f2")}</h5>
                                    <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t("f22")}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='tool nav-link'>
                                <LocalAtm className='icon' />
                                <div className='d-block mx-'>
                                    <h5 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t(10)}</h5>
                                    <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t(11)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='tool nav-link'>
                                <Headset className='icon' />
                                <div className='d-block mx-'>
                                    <h5 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t(12)}</h5>
                                    <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t(13)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-3'>
                            <div className='tool nav-link'>
                                <img src={Gerb} className="" />
                                <div className='d-block mx-'>
                                    <h5 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("f1")}</h5>
                                    <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t("f11")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardSimple;