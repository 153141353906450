import React, { useState } from "react";
import { Wrap } from "./style";
import './Scss/Categories.scss';
import CardSimple from "../Card/Card";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getName } from "../../languages/language";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import { fetchCategoriesData, fetchSearchProductsData } from "../../api/Api";

export const Categories = ({ all, main }) => {

  // carousel for categories in mainPage

  const [carousel, setCarousel] = useState(window.innerWidth > 1100 ? 10 : 8)
  const [activeCar, setActiveCar] = useState(false)

  function decrement() {
    setCarousel(window.innerWidth > 1100 ? 10 : 8)
    setActiveCar(true)
  }

  function increment() {
    setCarousel(window.innerWidth > 1100 ? carousel + 10 : carousel + 8)
    setActiveCar(false)
  }

  // i18nexus

  const { t } = useTranslation();
  let lang = localStorage.getItem('i18nextLng');

  // data of products

  let dataProducts = useQuery('search-products', fetchSearchProductsData);

  // data of categories

  const { data } = useQuery('categories', fetchCategoriesData);

  let Category = data?.data;

  return (
    <div className="Categories">
      <div className="wrapper">
        {all &&
          <Wrap.Title className="main-title" style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}` }}>
            <Wrap.Burger />
            {t(1)}
          </Wrap.Title>
        }
        {main &&
          <div className="outer">
            <div className="col-md-12 slide-tools">
              <div className="d-flex">
                <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}` }}>{t("browse")}</h1>
                {lang == "uz" &&
                  <p>({Category?.length} ta kategoriyalar)</p>
                }
                {lang == "ru" &&
                  <p>({Category?.length} категории)</p>
                }
                {lang == "en" &&
                  <p>({Category?.length} categories)</p>
                }
                {lang == "zh" &&
                  <p>({Category?.length} 類別)</p>
                }
                {lang == "sw" &&
                  <p>({Category?.length} kategoria)</p>
                }
                <Link className="nav-link" to="/categories" style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t("all")}</Link>
              </div>
            </div>
            <div className="category">
              {Category?.slice(0, carousel).map((data) => (
                <div key={data.id} className="one">
                  <Link to={`/products/${data.id}`} key={data.id} className="body">
                    <div className="img">
                      <img src={data.image} alt="" />
                      <div className="prod-length">{data?.count ? data?.count : 0} {lang == "uz" && "ta mahsulot"}{lang == "ru" && "продуктов"}{lang == "en" && "products"}{lang == "zh" && "個產品"}{lang == "sw" && "bidhaa"}</div>
                    </div>
                    <h4 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="name">{getName(data)}</h4>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        }
        {main &&
          <div className="more">
            {carousel < Category?.length ?
              <div style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="explore" onClick={() => increment()}>{lang == "uz" && "Ko'proq"}{lang == "ru" && "Более"}{lang == "en" && "More"}{lang == "zh" && "更多的"}{lang == "sw" && "Zaidi"} <ArrowDownward className="down-icon" /></div>
              : <div style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="explore" onClick={() => decrement()}>{lang == "uz" && "Yopish"}{lang == "ru" && "Неисправность"}{lang == "en" && "Close"}{lang == "zh" && "關閉"}{lang == "sw" && "Kuzimisha"} <ArrowUpward className="down-icon" /></div>
            }
          </div>}
        {all &&
          <div className="category">
            {Category?.map((data) => (
              // <div key={data.id} className="one">
              //   <Link to={`/products/${data.id}`} key={data.id} className="body">
              //     <img src={data.image} alt="" />
              //     <h4 style={{ fontFamily: `${lang =="ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="name">{lang == "en" && data.name_en} {lang =="ru" && data.name_ru} {lang == "uz" && data.name_uz} {lang == "zh" && data.name_ma} {lang == "sw" && data.name_sw}</h4>
              //   </Link>
              // </div>
              <div key={data.id} className="one">
                <Link to={`/products/${data.id}`} key={data.id} className="body">
                  <div className="img">
                    <img src={data.image} alt="" />
                    <div className="prod-length">{data?.count ? data?.count : 0} {lang == "uz" && "ta mahsulot"}{lang == "ru" && "продуктов"}{lang == "en" && "products"}{lang == "zh" && "個產品"}{lang == "sw" && "bidhaa"}</div>
                  </div>
                  <h4 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="name">{lang == "en" && data.name_en} {lang == "ru" && data.name_ru} {lang == "uz" && data.name_uz} {lang == "zh" && data.name_ma} {lang == "sw" && data.name_sw}</h4>
                </Link>
              </div>
            ))}
          </div>
        }
      </div>
      {all &&
        <CardSimple />
      }
    </div>
  );
};

export default Categories;
