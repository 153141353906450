import React from 'react';
import './Scss/Events.scss';
import Slider from 'react-slick';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { fetchEventsData } from '../../api/Api';
import { ArrowForward } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';

const Events = ({ individual, home }) => {

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
        ]
    };

    const { id } = useParams();

    // i18next

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    // data of events

    const { data } = useQuery('events', fetchEventsData);

    return (
        <div className='Events' id='events'>
            <div className="wrapper">
                {data?.data?.map((data) => (
                    data.id == id &&
                    <div className="one-new col-md-12">
                        <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }} className="one-name text-center">{lang == "uz" && data.name_uz}{lang == "ru" && data.name_ru}{lang == "en" && data.name_en}{lang == "zh" && data.name_ma}{lang == "sw" && data.name_sw}</h1>
                        <img className='one-img' src={data.image} />
                        <p className="one-desc">{lang == "uz" && data.description_uz}{lang == "ru" && data.description_ru}{lang == "en" && data.description_en}{lang == "zh" && data.description_ma}{lang == "sw" && data.description_sw}</p>
                    </div>
                ))}
                <div className="title col-md-12">
                    <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>{home && t("events")} {individual && lang == "en" && "Other activities"} {individual && lang == "ru" && "Другие занятия"} {individual && lang == "uz" && "Boshqa tadbirlar"} {individual && lang == "zh" && "其他活動"} {individual && lang == "sw" && "Shughuli nyingine"}</h1>
                </div>
                <Slider {...settings} className="carousel">
                    {data?.data?.map((data) => (
                        data.id != id && individual &&
                        <div className="news">
                            <div className="body">
                                <Link to={`/event${data.id}`} className="nav-link p-0">
                                    <img src={data.image} />
                                </Link>
                                <h6 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className="name">{lang == "uz" && data.name_uz}{lang == "ru" && data.name_ru}{lang == "en" && data.name_en}{lang == "zh" && data.name_ma}{lang == "sw" && data.name_sw}</h6>
                                <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="desc">{lang == "uz" && data.description_uz.slice(0, 60)}{lang == "ru" && data.description_ru.slice(0, 60)}{lang == "en" && data.description_en.slice(0, 60)}{lang == "zh" && data.description_ma.slice(0, 60)}{lang == "sw" && data.description_sw.slice(0, 60)}...</p>
                                <Link style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 800 }} to={`/event${data.id}`} className="more nav-link p-0">{lang == "en" && "More"} {lang == "ru" && "Более"} {lang == "uz" && "Ko'proq"} {lang == "zh" && "更多的"} {lang == "sw" && "Zaidi"} <ArrowForward className='right-icon' /></Link>
                            </div>
                        </div>
                    ))}
                </Slider>
                {home &&
                    <div className="carousel1">
                        {data?.data?.map((data) => (
                            data.id != id &&
                            <div className="news col-4">
                                <Link to={`/news/${data.id}`} className="body">
                                    <img src={data.image} />
                                    <h6 className="name">{lang == "uz" && data.name_uz}{lang == "ru" && data.name_ru}{lang == "en" && data.name_en}{lang == "zh" && data.name_ma}{lang == "sw" && data.name_sw}</h6>
                                    <p className="desc">{lang == "uz" && data.description_uz.slice(0, 60)}{lang == "ru" && data.description_ru.slice(0, 60)}{lang == "en" && data.description_en.slice(0, 60)}{lang == "zh" && data.description_ma.slice(0, 60)}{lang == "sw" && data.description_sw.slice(0, 60)}...</p>
                                    <div className="explore">{lang == "en" && "More"} {lang == "ru" && "Более"} {lang == "uz" && "Ko'proq"} {lang == "zh" && "更多的"} {lang == "sw" && "Zaidi"}</div>
                                </Link>
                            </div>
                        ))}
                    </div>
                }
            </div>
        </div>
    );
};

export default Events;