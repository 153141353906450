import React from 'react';
import './Scss/Advertising.scss';
import Slider from 'react-slick';
import { useQuery } from 'react-query';
import { fetchAdvertisingData } from '../../api/Api';
import { getDescription, getName } from '../../languages/language';

const Advertising = () => {

    const settings = {
        dots: true,
        speed: 2000,
        infinite: true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        slidesToScroll: 1,
        cssEase: "linear",
        autoplaySpeed: 6000,
        className: "slider variable-width",
    };

    let lang = localStorage.getItem('i18nextLng')

    // data of categories

    const { data } = useQuery('advertising', fetchAdvertisingData);

    return (
        <div className='Advertising' id="adver">
            <div className='wrapper'>
                <Slider {...settings}>
                    {data?.data?.map((item) => (
                        <div key={item.id} className='col-md-12'>
                            <div className='body nav-link'>
                                <img src={item.image} />
                                <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }} className='title'>{getName(item)}</h1>
                                <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='descript'>{getDescription(item)}</p>
                                <a style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} href={item.url} className="explore">{lang == "uz" && "Batafsil"}{lang == "ru" && "Более"}{lang == "en" && "More"}{lang == "sw" && "Zaidi"}{lang == "zh" && "更多的"}</a>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default Advertising;