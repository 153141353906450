import axios from 'axios';
import './Scss/SavedProducts.scss';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import ProductItem from '../ProductItem/ProductItem';
import { DATA_PRODUCTS_URL } from '../../api/ApiUrl';

const SavedProducts = () => {

    // i18next

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    // data of saved products

    let savedProducts = useSelector((state) => state.savedProducts.products);
    const [dataSavedProducts, setDataSavedProducts] = useState([]);
    let url = `${DATA_PRODUCTS_URL}?id__in=${savedProducts}`

    useEffect(() => {

        async function fetchSavedProducts() {
            const request = await axios.get(url);
            setDataSavedProducts(request?.data);
            return request;
        };

        fetchSavedProducts();

    }, [url]);

    return (
        <div className='SavedProducts'>
            <div className="wrapper">
                <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}><i className="fa fa-heart"></i>  {t("saved")}</h1>
                {savedProducts.length > 0 ?
                    <div className="products">
                        {dataSavedProducts?.results?.map((item) => (
                            <div key={item.id}>
                                <ProductItem mainData={item} />
                            </div>
                        ))}
                    </div>
                    :
                    <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{lang == "uz" ? "Siz bu yerga mahsulotlar saqlamagansiz" : lang == "en" ? "You have not saved any products here" : lang == "ru" ? "Вы не сохранили здесь ни одного товара" : lang == "zh" ? "您尚未在此處保存任何產品" : "Hujahifadhi bidhaa zozote hapa"}</h1>
                }
            </div>
        </div>
    );
};

export default SavedProducts;