import React from 'react';
import './Scss/News.scss';
import { useQuery } from 'react-query';
import { fetchNewsData } from '../../api/Api';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { getDescription, getName } from '../../languages/language';

const News = ({ individual, home, scrollId }) => {

    const { id } = useParams();

    // i18next

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng')

    // data of news

    const { data } = useQuery('news', fetchNewsData);

    return (
        <div className={`News ${scrollId && "d-none"}`} id='news'>
            <div className="wrapper">
                {data?.data?.map((data) => (
                    data.id == id &&
                    <div className="one-new col-md-12">
                        <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }} className="one-name text-center">{getName(data)}</h1>
                        <img className='one-img' src={data.image} />
                        <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="one-desc">{getDescription(data)}</p>
                    </div>
                ))}
                <div className="title col-md-12">
                    <h1 style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>{home && t("news")} {individual && lang == "en" && "Other news"} {individual && lang == "ru" && "Другие новости"} {individual && lang == "uz" && "Boshqa yangiliklar"} {individual && lang == "zh" && "其他新聞"} {individual && lang == "sw" && "Habari nyingine"}</h1>
                </div>
                <div className="carousel">
                    {data?.data?.map((data) => (
                        data.id != id &&
                        <div className="news col-4">
                            <Link to={`/news/${data.id}`} className="body">
                                <img src={data.image} />
                                <h6 style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} className="name">{getName(data)}</h6>
                                <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className="desc">{lang == "uz" && data.description_uz.slice(0, 60)}{lang == "ru" && data.description_ru.slice(0, 60)}{lang == "en" && data.description_en.slice(0, 60)}{lang == "zh" && data.description_ma.slice(0, 60)}{lang == "sw" && data.description_sw.slice(0, 60)}...</p>
                                <div style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 800 }} className="explore">{lang == "en" && "More"} {lang == "ru" && "Более"} {lang == "uz" && "Ko'proq"} {lang == "zh" && "更多的"} {lang == "sw" && "Zaidi"}</div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default News;