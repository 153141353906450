import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './Scss/Contacts.scss';
import Img from '../../assets/imgs/contacts.png';

const Contacts = ({ changeModal }) => {

    const [nameValue, setNameValue] = useState("")
    const [numberValue, setNumberValue] = useState("")
    const [value, setValue] = useState("")

    const [redName, setRedName] = useState(false);
    const [redNumber, setRedNumber] = useState(false)
    const [redText, setRedText] = useState(false)

    function handleChange1(item) {
        setNameValue(item)
        setRedName(false)
    }
    function handleChange2(item) {
        setNumberValue(item)
        setRedNumber(false)
    }
    function handleChange3(item) {
        setValue(item)
        setRedText(false)
    }

    let bot = {
        TOKEN: "5500966862:AAFWxVHRtMUeL4gr5NhS_OHkA9CWfIRW18o",
        chatID: "-1001566786338",
        message: `Ismi: ${nameValue}; %0ATelefon raqami: ${numberValue}; %0AXabar: ${value}`
    }

    function sendMessage(e) {
        if (nameValue === "") {
            setRedName(true)
        } else if (value === "") {
            setRedText(true)
        } else if (numberValue === "") {
            setRedNumber(true)
        } else {
            fetch(`https://api.telegram.org/bot${bot.TOKEN}/sendMessage?chat_id=${bot.chatID}&text=${bot.message} `, {
                method: "GET"
            })
                .then(success => {
                    console.log('send message')
                }, error => {
                    console.log(error)
                })

            setNameValue("")
            setNumberValue("")
            setValue("")
            changeModal()
        }
    }

    // i18next

    const { t } = useTranslation()
    let lang = localStorage.getItem('i18nextLng')

    return (
        <div className='Contacts' id="contacts" >
            <div className="wrapper">
                <div className="cards col-12">
                    <img src={Img} alt="img" className='coin' />
                    <div className="col-6 forms">
                        <h1 className='title' style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}`, fontWeight: 700 }}>{t("c0")}</h1>
                        <p style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }}>{t("c")}</p>
                        <input style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} value={nameValue} onChange={(e) => handleChange1(e.target.value)} className={`forms-control ${redName && "red-line"}`} type="text" placeholder={`${t("c1")}`} />
                        <input style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} value={numberValue} onChange={(e) => handleChange2(e.target.value)} className={`forms-control ${redNumber && "red-line"}`} type="number" placeholder={`${t("c2")}`} />
                        <textarea style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} value={value} onChange={(e) => handleChange3(e.target.value)} rows="6" className={`forms-control ${redText && "red-line"}`} placeholder={`${t("c3")}`}></textarea>
                        <button style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }} onClick={() => sendMessage()} className='btn btn-block'>{t("c4")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;