import React, { useEffect, useRef, useState } from 'react';
import './Scss/Navbar.scss';
import Logo from './images/logo.png';
import Audio from './audio/audio.mp3';
import { useQuery } from 'react-query';
import China from './images/china.png';
import Kenya from './images/kenya.png';
import { Link } from 'react-router-dom';
import Russia from './images/russia.png';
import { useSelector } from 'react-redux';
import English from './images/english.png';
import { useTranslation } from 'react-i18next';
import Uzbekistan from './images/uzbekistan.png';
import { changeLang, getName } from '../../languages/language';
import { Close, Menu, VolumeOff, VolumeUp } from '@mui/icons-material';
import { fetchCategoriesData, fetchSearchProductsData, fetchSubCategoriesData } from '../../api/Api';

const Navbar = ({ changeScrollNews, changeScrollEvents }) => {

    // responsive navbar-nav show modal

    const [show, setShow] = useState(false)

    // language functions

    const { i18n, t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng');
    const [language, setLanguage] = useState(false)
    const [langTitle, setLangTitle] = useState(localStorage.getItem('i18nextLng').toUpperCase())
    const [mainImg, setMainImg] = useState(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') == "en" ? English : localStorage.getItem('i18nextLng') == "ru" ? Russia : localStorage.getItem('i18nextLng') == "uz" ? Uzbekistan : localStorage.getItem('i18nextLng') == "zh" ? China : Kenya : '')

    function changeLangValue(item) {
        changeLang(item);
        setLanguage(false);
        i18n.changeLanguage(item);
        setLangTitle(localStorage.getItem('i18nextLng').toUpperCase())
    };

    // category bar

    const [catBar, setCatBar] = useState(false)
    const [searchBar, setSearchBar] = useState(false)

    function openCategory(item) {
        setCatBar(!catBar)
        setSearchBar(!item)
    };

    // audio

    const audio = useRef(null)
    const [volume, setVolume] = useState(false)

    function playVolume() {
        setVolume(true)
        audio.current.play()
    };

    function endVolume() {
        setVolume(false)
        audio.current.pause()
    };

    const [cookie, setCookie] = useState(true)

    function showCookie(item) {
        playVolume()
        setCookie(item)
        localStorage.setItem("cookie", item)
    }

    useEffect(() => {
        setTimeout(() => {
            setCookie(localStorage.getItem("cookie") ? JSON.parse(localStorage.getItem("cookie")) : false)
        }, 2000);
    }, [])

    // counter numbers

    let addedProducts = useSelector((state) => state.cartProducts.products);
    let savedProducts = useSelector((state) => state.savedProducts.products);

    // data of categories

    let dataCategories = useQuery('categories', fetchCategoriesData);

    // data of products

    let dataSubCategories = useQuery('sub-categories', fetchSubCategoriesData);

    // data of products

    let dataProducts = useQuery('search-products', fetchSearchProductsData);

    // search filter

    const [inputValue, setInputValue] = useState("");

    function clickedSearch(item, post) {
        setCatBar(post)
        setSearchBar(item)
    };

    function closeSearchBar() {
        setInputValue("")
        clickedSearch(false, false)
    };

    function localId() {
        setInputValue("")
        clickedSearch(false)
    };

    function filterData(e) {
        setInputValue(e.target.value)
        clickedSearch(true, false)
    };

    const dataProds = dataProducts?.data?.data?.filter(c => getName(c)?.toLowerCase().includes(inputValue.toLowerCase()));
    const dataCategs = dataCategories?.data?.data?.filter(c => getName(c)?.toLowerCase().includes(inputValue.toLowerCase()));
    const dataSubCategs = dataSubCategories?.data?.data?.filter(c => getName(c)?.toLowerCase().includes(inputValue.toLowerCase()));

    return (
        <>
            <div className={`cookie ${cookie && "d-none"}`}>
                <p>{t("cookie")}</p>
                <a href='cookie.html' target="_blank" className='' onClick={() => showCookie(true)}>{t("cust")}</a>
                <button className="btn accept" onClick={() => showCookie(true)}>{t("accept")}</button>
            </div>
            <div className='Navbar1'>
                <nav className="navbar navbar-expand">
                    <div className="col-12 top-navbar">
                        <Link to="/" className="logo" onClick={() => window.scrollTo(0, 0)}>
                            <img src={Logo} alt="logo" />
                        </Link>
                        <audio autoPlay src={Audio} type="audio/mpeg" ref={audio}></audio>
                        <div className="search-bar">
                            <input style={{ fontFamily: `${lang == "ru" ? "CraftWork400" : "zonapro"}` }} type="search" className={`${inputValue != "" && "active"} ${searchBar && "activ"}`} value={inputValue} onChange={(e) => filterData(e)} placeholder={`${t("search")}...`} />
                            <i className='fa fa-search'></i>
                            <ul className={`search-filter ${!searchBar && "d-none"} ${inputValue == "" && "d-none"}`}>
                                {dataProds?.map((data) => (
                                    <li key={data.id}>
                                        <Link to={`/product/${data.id}`} className='result nav-link' onClick={() => clickedSearch(false)}>{getName(data)}</Link>
                                    </li>
                                ))}
                                {dataCategs?.map((data) => (
                                    <li key={data.id}>
                                        <Link to={`/products/${data.id}`} className='result nav-link' onClick={() => localId(data.id)}>{getName(data)}</Link>
                                    </li>
                                ))}
                                {dataSubCategs?.map((data) => (
                                    <li key={data.id}>
                                        <Link to={`/products/${data.category}`} className='result nav-link' onClick={() => localId(data.category, data.id)}>{getName(data)}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Menu className="fa fa-bars" onClick={() => setShow(true)} />
                        <ul className="navbar-nav">
                            <li className="nav-item">
                                <Link to="/saved" className="nav-link"><i className='fa fa-heart'><div>{savedProducts?.length}</div></i></Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/cart" className="nav-link"><i className='fa fa-shopping-cart'><div>{addedProducts?.length}</div></i></Link>
                            </li>
                            <li className='nav-item'>
                                <VolumeOff className={`volume ${volume && "d-none"}`} onClick={() => playVolume()} />
                                <VolumeUp className={`volume ${!volume && "d-none"}`} onClick={() => endVolume()} />
                            </li>
                            <li className="nav-item language" onClick={() => clickedSearch(false)}>
                                <input type="checkbox" id="language" checked={language} onChange={() => setLanguage(!language)} />
                                <label htmlFor='language'>{langTitle} <i className="fa fa-angle-down"></i></label>
                                <ul className={`language-menu ${!language && "d-none"}`}>
                                    <li onClick={() => changeLangValue("ru")}>RU</li>
                                    <li onClick={() => changeLangValue("en")}>EN</li>
                                    <li onClick={() => changeLangValue("uz")}>UZ</li>
                                    <li onClick={() => changeLangValue("zh")}>ZH</li>
                                    <li onClick={() => changeLangValue("sw")}>SW</li>
                                    {/* <img src={mainImg} className='main-img' /> 
                                    <img src={Russia} className='lang-img' />
                                    <img src={English} className='lang-img' />
                                    <img src={Uzbekistan} className='lang-img' />
                                    <img src={China} className='lang-img' />
                                    <img src={Kenya} className='lang-img' /> */}
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className={`col-12 bottom-navbar ${show && "open-navbar"}`}>
                        <ul className='navbar-nav'>
                            <li className='nav-item category-menu' onClick={() => openCategory(true)}>
                                <span style={{ fontFamily: `${lang == "ru" ? "CraftWork700" : "zonapro-bold"}` }}>{t(1)}</span>
                                <div className={`category-tools ${!catBar && "d-none"}`}>
                                    {dataCategories?.data?.data?.map((data) => (
                                        <Link to={`/products/${data.id}`} key={data.id} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} className='result nav-link' onClick={() => localId(data.id)}>{getName(data)}</Link>
                                    ))}
                                </div>
                            </li>
                            <li className='nav-item'>
                                <Link to="/aboutus" className='nav-link' style={{ fontFamily: `${lang == "ru" ? "CraftWork400" : "zonapro"}` }}>{t(2)}</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/categories" className='nav-link' style={{ fontFamily: `${lang == "ru" ? "CraftWork400" : "zonapro"}` }}>{t("producst")}</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/" className='nav-link' onClick={() => changeScrollNews()} style={{ fontFamily: `${lang == "ru" ? "CraftWork400" : "zonapro"}` }}>{t("news")}</Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="/" className='nav-link' onClick={() => changeScrollEvents()} style={{ fontFamily: `${lang == "ru" ? "CraftWork400" : "zonapro"}` }}>{t(7)}</Link>
                            </li>
                            <li className='nav-item'>
                                <a href='#contacts' className='nav-link' style={{ fontFamily: `${lang == "ru" ? "CraftWork400" : "zonapro"}` }}>{lang == "uz" && "Aloqa"}{lang == "ru" && "Коммуникация"}{lang == "en" && "Communication"}{lang == "zh" && "溝通"}{lang == "sw" && "Mawasiliano"}</a>
                            </li>
                            <Close className="fa fa-times" onClick={() => setShow(false)} />
                            <div className={`contr-category1 ${!catBar && "d-none"}`} onClick={() => openCategory(true)}></div>
                        </ul>
                    </div>
                </nav>
            </div>
            <div className={`contr-lang ${!language && "d-none"}`} onClick={() => setLanguage(false)}></div>
            <div className={`contr-category ${!catBar && "d-none"}`} onClick={() => openCategory(true)}></div>
            <div className={`contr-search ${!searchBar && "d-none"}`} onClick={() => closeSearchBar()}></div>
        </>
    );
};

export default Navbar;