let lang = localStorage.getItem('i18nextLng');

export const changeLang = (item) => {
    lang = item
};

export const getName = (item) => {
    if (lang == "uz") {
        return item.name_uz
    } else if (lang == "ru") {
        return item.name_ru
    } else if (lang == "en") {
        return item.name_en
    } else if (lang == "zh") {
        return item.name_ma
    } else {
        return item.name_sw
    }
};

export const getDescription = (item) => {
    if (lang == "uz") {
        return item.description_uz
    } else if (lang == "ru") {
        return item.description_ru
    } else if (lang == "en") {
        return item.description_en
    } else if (lang == "zh") {
        return item.description_ma
    } else {
        return item.description_sw
    }
};