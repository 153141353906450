import axios from 'axios';
import './Scss/Products.scss';
import CardSimple from '../Card/Card';
import { useQuery } from 'react-query';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { getName } from '../../languages/language';
import { Link, useParams } from 'react-router-dom';
import ProductItem from '../ProductItem/ProductItem';
import { DATA_PRODUCTS_URL } from '../../api/ApiUrl';
import { fetchCategoriesData, fetchSubCategoriesData } from '../../api/Api';

const Products = () => {

    const { id } = useParams();

    // i18next

    const { t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng');

    // data of categories

    const dataCategories = useQuery('categories', fetchCategoriesData);

    // data of categories

    const dataSubCategories = useQuery('subcategories', fetchSubCategoriesData);

    // data of all products

    const [pageId, setPageId] = useState('');
    const [dataProducts, setDataProducts] = useState([]);
    const [subCategoryId, setSubCategoryId] = useState('');
    let url = pageId == '' ? `${DATA_PRODUCTS_URL}?id=&id__in=&category=${id}&top=&sub_category=${subCategoryId}` : `${DATA_PRODUCTS_URL}?category=${id}&id=&id__in=&page=${pageId}&sub_category=${subCategoryId}&top=`

    useEffect(() => {

        async function fetchProducts() {
            const request = await axios.get(url);
            setDataProducts(request?.data);
            return request;
        };

        fetchProducts();

    }, [url]);

    // icon tools

    const [openCategory, setOpenCategory] = useState(false);
    const [openSubCategory, setOpenSubCategory] = useState(false);

    return (
        <>
            <div className='Products'>
                <div className="wrapper">
                    <h1 className='top-title' style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro-bold"}`, fontWeight: 700 }}>{t("producst")}</h1>
                    <div className="categories">
                        <div className={`tools ${openCategory && "open"}`}>
                            {dataCategories?.data?.data?.map((item) => (
                                <Link to={`/products/${item.id}`} key={item.id} className={`category ${item.id == id && "active-category"}`} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} onClick={() => [setSubCategoryId(''), setPageId('')]}>{getName(item)}</Link>
                            ))}
                        </div>
                        <div className="icon" onClick={() => setOpenCategory(!openCategory)}>
                            <i className={`fa fa-angle-right ${openCategory && "rotating"}`}></i>
                        </div>
                    </div>
                    <div className="sub-categories">
                        <div className={`tools ${openSubCategory && "open"}`}>
                            {dataSubCategories?.data?.data?.filter((c) => c.category == id).map((item) => (
                                <div key={item.id} className={`sub-category ${item.id == subCategoryId && "active-sub-category"}`} style={{ fontFamily: `${lang == "ru" ? "CraftWork600" : "zonapro"}`, fontWeight: 700 }} onClick={() => [setSubCategoryId(item.id), setPageId('')]}>{getName(item)}</div>
                            ))}
                        </div>
                        <div className="icon" onClick={() => setOpenSubCategory(!openSubCategory)}>
                            <i className={`fa fa-angle-right ${openSubCategory && "rotating"}`}></i>
                        </div>
                    </div>
                    <div className="products">
                        {dataProducts?.results?.map((item) => (
                            <div key={item.id}>
                                <ProductItem mainData={item} />
                            </div>
                        ))}
                    </div>
                    <div className="paginations">
                        <Pagination count={Math.ceil(dataProducts?.count / 8)} shape="rounded" onChange={(page) => setPageId(page.target.innerText)} className="pagination-item" />
                    </div>
                </div>
            </div>
            <CardSimple />
        </>
    );
};

export default Products;